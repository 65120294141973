/**
 * Created by Stefan Vilbrandt <stefan.vilbrandt@web-for-life.com> on 25.08.16.
 */

/**
 * run this code as soon as possible
 * script should be included right before the closing body tag
 */
(function() {
    var $ = jQuery;
    
    /**
     * Find all external links and add attributes "noopener" and "noreferrer"
     * <a href="https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/">why? look here</a>
     */
    function secureExternalLinks() {
        var targetBlank = document.querySelectorAll('a[target="_blank"]');
        for (var i = 0; i < targetBlank.length; i++) {
            if (!targetBlank[i].hasAttribute("rel") && !targetBlank[i].hasAttribute("data-blank")) {
                targetBlank[i].setAttribute("rel", "noopener noreferrer");
            }
        }
    }
    
    /**
     * Add swipe ability to bootstrap carousel.
     *
     * Need jQuery and libs/TouchSwipe-Jquery-Plugin-wfl-updated.
     */
    function swipeBootstrapCarousels() {
        var $carousels = $(".carousel-inner");
        
        /**
         * do nothing of there is no carousel or swipe library (TouchSwipe-Jquery-Plugin) was not included
         */
        if($carousels.length <= 0 || typeof $.fn.swipe !== "function") {
            return;
        }
        
        $carousels.swipe({
            swipeLeft: function() {
                $(this).parent().carousel("prev");
            },
            swipeRight: function() {
                $(this).parent().carousel("next");
            }
        });
    }
    
    /**
     * fallback for form-attribute of buttons. widely accepted except IE/EDGE
     * <a href="http://caniuse.com/#search=form">caniuse</a>
     */
    function formAttributeForSubmitButtons() {
        document.body.addEventListener("click", function (e) {
            var target = e.target;
            
            if (target.nodeName.toLowerCase() === "button" && target.hasAttribute("form")) {
                e.preventDefault();
                document.getElementById(target.getAttribute("form")).submit();
            }
        });
    }
    
    /**
     * for whatever reason Bootstrap does not change the text of the dropDown-button after selecting an item,
     * so we have to do it our self. Only change needed to original Bootstrap dropDown is to wrap an element with
     * the class "dropdown-text" around the "Action"-text
     *
     * But do not do this changes if the dropdown is a multi-select.
     */
    function setButtonTextForBootstrapDropdown() {
        if (typeof jQuery !== "undefined") {
            jQuery(function ($) {
                $("body").on("click", ".dropdown-menu .dropdown-item", function (e) {
                    var $dropDownMenu = $(this).closest(".dropdown-menu");
    
                    if (!$dropDownMenu.data("multiple")) {
                        // fill button with text of selected item
                        $dropDownMenu
                            .siblings(".dropdown-toggle")
                            .find(".dropdown-text")
                            .html($(this).find(".text").html());
    
                        // close dropdownlist
                        $("#" + $dropDownMenu.attr("aria-labelledby")).dropdown("toggle");
                    }
                });
            });
        }
    }
    
    secureExternalLinks();
    swipeBootstrapCarousels();
    formAttributeForSubmitButtons();
    setButtonTextForBootstrapDropdown();
})();